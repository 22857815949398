import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import ImageService from '../libs/imageService';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

const MetaData = ({ story }) => {
  const { site } = useStaticQuery(query);
  const { name, full_slug, content } = story;
  const { hero, metadata, Meta, component } = content;

  let description, image;

  const metaData = metadata ? metadata : Meta;

  const pageSpecificImg = content?.og_image?.filename;

  const cannocial_url_part =
    full_slug && (full_slug === 'home' ? '' : full_slug + (full_slug.endsWith('/') ? '' : '/'));
  let canonical = site.siteMetadata.siteUrl + '/' + cannocial_url_part;

  if (component === 'case') {
    metaData.title = `${name} x Grebban`;
  }

  if (component === 'blogPost') {
    image =
      content.image && content.image.filename ? ImageService(content.image.filename, '1200x0', 'jpeg', 75) : undefined;
    description = content.description;
    canonical = canonical.replace('/posts/', '/');
  } else if (component === 'blogCategory') {
    description = content.description;
  } else {
    if (hero && hero[0]) {
      description =
        hero[0].subheading && hero[0].subheading.replace(' — ', '').replace('— ', '').replace('—', '').replace('–', '');

      let img = hero[0].background ? hero[0].background.filename : undefined;
      image = img && ImageService(img, '1200x0', 'jpeg', 75);
    }

    if (pageSpecificImg) {
      image = pageSpecificImg && ImageService(pageSpecificImg, '1200x0', 'jpeg', 75);
    }
  }

  // Always override description if we have one
  if (metaData?.description) {
    description = metaData.description;
  }

  const seo = {
    title: metaData?.title || name,
    description: description || '',
    image: `https:${image}` || '',
    canonical: canonical || '',
    type: 'website',
    domain: 'grebban.com',
  };

  return (
    <Helmet defer={false}>
      {/* <!-- HTML Meta Tags --> */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={seo.canonical} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      {/* <!-- Twitter Meta Tags --/> */}
      <meta name="twitter:card" content={seo.title} />
      <meta property="twitter:domain" content={seo.domain} />
      <meta property="twitter:url" content={seo.canonical} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  );
};

export default MetaData;
