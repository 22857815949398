import Footer from '../components/layouts/Footer';
import Header from '../components/layouts/Header';
import Pages from '../load-page';
import React from 'react';
import SiteTheme from '../components/SiteTheme';
import SEO from '../components/SEO';
import Calendly from '../components/layouts/Calendly';

class StoryblokEntry extends React.Component {
  render() {
    const { story, header, footer, ...rest } = this.props.pageContext;

    const hideCalendlyOnSlugs = ['e-commerce-design-audit', 'designsubscription'];
    const hideFooterOnSlugs = ['designsubscription'];

    const hideCalendly = hideCalendlyOnSlugs.some(slug => story.slug === slug);
    const hideFooter = hideFooterOnSlugs.some(slug => story.slug === slug);

    return (
      <>
        <SEO type="website" story={story} />
        <SiteTheme />
        <Header blok={header.content} />
        <main id="main" className={story.content.component}>
          {story && <Pages story={story} />}
        </main>
        {/* Design Choice to not have Calendly on E-commerce Design Audit Page */}
        {!hideCalendly && <Calendly />}
        {!hideFooter && <Footer blok={footer.content} />}
      </>
    );
  }
}

export default StoryblokEntry;
