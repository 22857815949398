import { useDispatch, useSelector } from 'react-redux';

import { changeSiteColorTheme } from 'helpers/changeSiteColorTheme';
import { resetTheme } from 'state/Site/actions';
import { useEffect } from 'react';
import { globalHistory } from '@reach/router';

/**
 * Theme component that handles site theme on the page.
 */

const SiteTheme = () => {
  const theme = useSelector(state => state.site.theme) || [];
  const dispatch = useDispatch();
  //const location = useLocation();
  const hasTheme = theme.length;

  // Reset theme between pages
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && hasTheme) dispatch(resetTheme());
    });
  }, [hasTheme, dispatch]);

  // Change between active and light (default) theme
  useEffect(() => {
    if (hasTheme) {
      changeSiteColorTheme(theme[0]);
    } else {
      changeSiteColorTheme('light');
    }

    return () => changeSiteColorTheme('light');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return null;
};

export default SiteTheme;
