import { themes } from '../config/theme';
import { inServer } from '../config/theme/constants';
import type { ColorTheme } from '../config/theme';
import type { Theme } from '../config/theme/themes';

const cssVariables: Array<{ variable: string; property: keyof Theme }> = [
  { variable: '--theme-background-color', property: 'backgroundColor' },
  { variable: '--theme-color', property: 'color' },
];

export const changeSiteColorTheme = (colorTheme: ColorTheme = 'light') => {
  const theme = themes[colorTheme] || themes['light'];
  const root = !inServer ? document.documentElement : null;

  if (root) {
    cssVariables.forEach(({ variable, property }) => {
      root.style.setProperty(variable, theme[property]);
    });
  }
};
